/* The Ubuntu font */
@font-face {
  font-family: 'UbuntuRegular';
  src: url('Ubuntu-R-webfont.eot');
  src: local('☺'),
  url('#{$font-path}/Ubuntu-R-webfont.woff') format('woff'),
  url('#{$font-path}/Ubuntu-R-webfont.ttf') format('truetype'),
  url('#{$font-path}/Ubuntu-R-webfont.svg#webfontcceGD1qN') format('svg') {}
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'UbuntuItalic';
  src: url('#{$font-path}/Ubuntu-I-webfont.eot');
  src: local('☺'),
  url('#{$font-path}/Ubuntu-I-webfont.woff') format('woff'),
  url('#{$font-path}/#{$font-path}/Ubuntu-I-webfont.ttf') format('truetype'),
  url('#{$font-path}/Ubuntu-I-webfont.svg#webfontPxVORYT4') format('svg') {}
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'UbuntuBold';
  src: url('#{$font-path}/Ubuntu-B-webfont.eot');
  src: local('☺'),
  url('#{$font-path}/Ubuntu-B-webfont.woff') format('woff'),
  url('#{$font-path}/Ubuntu-B-webfont.ttf') format('truetype'),
  url('#{$font-path}/Ubuntu-B-webfont.svg#webfontssbCkuz5') format('svg') {}
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'UbuntuBoldItalic';
  src: url('#{$font-path}/Ubuntu-BI-webfont.eot');
  src: local('☺'),
  url('#{$font-path}/Ubuntu-BI-webfont.woff') format('woff'),
  url('#{$font-path}/#{$font-path}/Ubuntu-BI-webfont.ttf') format('truetype'),
  url('#{$font-path}/Ubuntu-BI-webfont.svg#webfont3JT5vc7d') format('svg') {}
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'EntypoRegular';
  src: url('#{$font-path}/entypo-webfont.eot');
  src: local('☺'),
  url('#{$font-path}/entypo-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/entypo-webfont.woff') format('woff'),
  url('#{$font-path}/entypo-webfont.ttf') format('truetype'),
  url('#{$font-path}/entypo-webfont.svg#EntypoRegular') format('svg') {}
  font-weight: normal;
  font-style: normal; }
