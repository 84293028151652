$theme: #337ab7;
$light: $haze;
$font-path: $fontsPath;

/* Not sure whey the original theme doesn't use the sass vars */
html {
  --font: UbuntuRegular, sans-serif;
  --theme: #{$theme};
  --light: #{$light};
  --haze: #{$haze};
  --bg: #{$bg}; }
